import React from "react";

const Own = () => {
  return (
    <div>
      
      <p className="text-center">אין לך עדיין דיונים כלשהם. לחץ על "חדש" כדי ליצור דיון משלך.</p>
    </div>
  );
};

export default Own;