import React from "react";

const Respond = () => {
  return (
    <div>
      
      <p className="text-center">אין לך שום התראות לטפל בהן כרגע.</p>
    </div>
  );
};

export default Respond;