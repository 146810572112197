import {Container} from "react-bootstrap"
import Header from "../../../Layouts/Header";

function Privacy() {
    return (
        <>
            <Header />
            <div class="privacy-dv" dir="rtl">
                <section class="privacy-pg" >
                    <Container>
                        <p class="text-danger">התנאים וההגבלות של .. ומדיניות הפרטיות מתעדכנים כעת כדי לשקף שהם תואמים ל-GDPR ול-CCPA.</p>
                        <p class="text-danger">..... הוא אתר חינמי שאינו מניב הכנסות. אין לו מודעות, וגם לא נעשה שימוש לרעה בנתוני המשתמשים או נמכרים מחדש. נתוני המשתמש משמשים לשיפור האתר, למשל. כדי לראות באילו פונקציות נעשה שימוש תכוף, כדי להציג תוכן משתמשים, ולהפוך את האתר לידידותי על ידי זיהוי הטענות שהמשתמשים כבר ראו.</p>
                        <p class="text-danger my-5 mt-0">אנא צור איתנו קשר אם יש לך שאלות כלשהן.</p>
                        <p class=" my-5">8 בספטמבר 2023</p>
                        <h1 class="text-primary my-5" >מדיניות הפרטיות</h1>
                        <p>אנא קרא בעיון את מדיניות הפרטיות הזו. זה חל על כל האינטראקציות שיש לך עם שירותי האינטרנט והאפליקציות של </p>
                        <h2 class="text-primary">המידע שאנו אוספים</h2>
                        <p><span class="text-decoration-underline">פרטי חשבון.</span> כאשר אתה יוצר חשבון עם Kialo, אנו מבקשים ממך לבחור שם משתמש וסיסמה. אנו מבקשים גם את כתובת המייל שלך. אתה יכול גם לבחור לספק מידע פרופיל נוסף כגון תמונה, שמך האמיתי, ביוגרפיה קצרה וקישורים לחשבונות מדיה חברתית.</p>
                        <p><span class="text-decoration-underline">מידע על השימוש שלך ב</span> אנו מקבלים מידע מסוים באופן אוטומטי כאשר אתה משתמש ב-Kialo. זה כולל נתונים על המכשיר שלך, התוכנה, מערכת ההפעלה שבה אתה משתמש בעת גישה ל-Kialo, כתובת פרוטוקול האינטרנט שלך, התאריך והשעה של כל בקשה שתגיש ל-Kialo, סוגי קבצים שהועברו (למשל, תמונות) ומספר וגודל הקבצים הועבר.</p>
                        <p><span class="text-decoration-underline">שירות לקוחות.</span> אם תגיש כרטיס תמיכה, נאסוף כל מידע אישי אחר שתבחר לספק לנו.</p>
                        <p><span class="text-decoration-underline">מידע על אנשים שאתה מזמין להצטרף לצוות או לדיון.</span> כאשר אתה מזמין את החברים והקולגות שלך להצטרף לצוות או לדיון ב-Kialo, אנו נאחסן את כתובות הדוא"ל שתספק כדי לאמת את המשתמשים הללו.</p>
                        <h2 class="text-primary">כיצד אנו משתמשים במידע שלך</h2>
                        <p>אנו משתמשים במידע האישי שלך כדי להשאיר את Kialo פועלת, להבין כיצד אתה משתמש בשירות שלנו, להתאים אישית את החוויה שלך, למנוע שימוש לרעה, לספק תמיכת לקוחות ולשפר את Kialo. אנו משתמשים במידע שלך באופן פנימי רק לפי הצורך כדי להשיג מטרות אלו.</p>
                        <h2 class="text-primary">כיצד אנו חושפים את המידע שלך</h2>
                        <p>אנו נשתף את המידע המאפשר זיהוי אישי שלך רק בנסיבות המצומצמות להלן. Kialo אינה מוכרת את המידע שלך או משתפת אותו עם מפרסמים של צד שלישי.</p>
                        <p><span class="text-decoration-underline">באישור שלך.</span> אנו עשויים לחלוק את המידע שלך בהסכמתך, לאחר שנודיע לך איזה מידע ישותף ועם מי.</p>
                        <p><span class="text-decoration-underline">בתגובה לחוק.</span> אנו עשויים לחשוף את המידע שלך אם אנו סבורים שזה הכרחי באופן סביר כדי לציית לחוק, לתקנה או להליך משפטי תקף. אם אנו מתכוונים לשחרר את המידע שלך, המדיניות שלנו היא לספק לך הודעה, אלא אם נאסר עלינו לעשות זאת על פי חוק או צו בית משפט (לדוגמה, צו לפי 18 U.S.C. § 2705(b)). אנו עשויים לחשוף את המידע שלך מבלי למסור לך הודעה מוקדמת אם אנו סבורים שזה הכרחי כדי למנוע נזק גופני קרוב וחמור לאדם. שום דבר במדיניות זו לא נועד להגביל התנגדויות או הגנות משפטיות שייתכן שתצטרך לדרישות לחייב את גילוי המידע שלך, לרבות דרישות מהממשלה.</p>
                        <p><span class="text-decoration-underline">עם צדדים שלישיים שעוזרים לנו לספק</span> אנו עשויים לחלוק את המידע שלך עם שירותי צד שלישי שיעזרו לנו לספק ולשפר את המוצרים שלנו. אותם צדדים שלישיים ניגשים למידע שלך ומשתמשים בהם רק בשמנו. שירותי הצד השלישי שבהם אנו משתמשים כדי לספק את Kialo כוללים את Amazon AWS, Zendesk, Sentry, Google Analytics, Loggly, Slack, PagerDuty ו-Postmark. לידיעתך, מדיניות פרטיות זו מסבירה את הנהלים של Kialo בלבד, ואינה מרחיבה את הנוהלים של שירותים אחרים. עיין במדיניות הפרטיות של חברות אלה כדי ללמוד עוד על נוהלי הנתונים שלהן.</p>
                        <p><span class="text-decoration-underline">עם משתמשים אחרים.</span> עשוי להציג את פרטי הפרופיל שלך לאנשים אחרים. אנחנו גם מציגים דיונים ציבוריים שבהם היית פעיל כברירת מחדל, אבל אתה יכול לבחור להסתיר את ציר הזמן הזה.</p>
                        <p><span class="text-decoration-underline">מכירה או מיזוג.</span> אם כל או חלקו של ... יימכר, יתמזג או יועבר בדרך אחרת לחברה אחרת בעתיד, ייתכן שהמידע שלך יועבר כחלק מאותה עסקה. אם זה יקרה, ינקוט בצעדים סבירים כדי לוודא שהמידע שלך ימשיך להיות מטופל באופן עקבי עם מדיניות פרטיות זו.</p>
                        <p>אנו עשויים גם לחשוף מידע מצטבר, לא מזהה על האופן שבו המשתמשים שלנו משתמשים ....</p>
                        <h2 class="text-primary">מדיניות מעקב אינטרנט</h2>
                        <p><span class="text-decoration-underline">עוגיות.</span> אנו משתמשים בעוגיות, שהם קבצי טקסט המוצבים במחשב שלך, כדי לזהות אם התחברת ולזהות שדפדפן האינטרנט שלך ניגש לשרתים שלנו בעבר.</p>
                        <p>לרוב הדפדפנים יש אפשרות לנטרל קובצי .. אבל אם תשבית אותם לא תוכל להיכנס ל.....</p>
                        <p>חלק משירותי צד שלישי שבהם אנו משתמשים עשויים להציב מזהים משלהם בדפדפן שלך. מדיניות פרטיות זו מכסה את השימוש שלנו בעוגיות בלבד ולא את השימוש בעוגיות על ידי צדדים שלישיים.</p>
                        <p><span class="text-decoration-underline">אחסון מקומי.</span> אחסון מקומי מספק פונקציונליות דומה לקובצי Cookie, אך התוכן שלו נשמר במכשיר שלך ואינו מועבר דרך האינטרנט, כמו שעוגיות. בדומה לעוגיות, אנו משתמשים בטכנולוגיות אחסון מקומיות כדי לאסוף ולאחסן מידע (כולל מידע אישי) כדי לזהות אם התחברת וזיהית שניגשת בעבר.</p>
                        <p><span class="text-decoration-underline">אל תעקוב.</span> דפדפנים מסוימים מאפשרים לך לאותת שאינך רוצה שתעקוב אחר פעילות הגלישה שלך באינטרנט. קהילת האינטרנט לא הגיעה לקונצנזוס כיצד לכבד את האותות הללו בצורה הטובה ביותר, ולכן ..... אינה מגיבה לבקשות אל תעקוב בשלב זה.</p>
                        <p><span class="text-decoration-underline">אימייל.</span> כדי למדוד את יכולת המסירה של הודעות הדואר האלקטרוני שלנו למשתמשים, אנו עשויים להטמיע בהם מידע, כגון משואת אינטרנט או תג.</p>
                        <p><span class="text-decoration-underline">הערה מיוחדת לגבי גוגל אנליטיקס.</span> אתר זה משתמש ב-Google Analytics, שירות ניתוח אינטרנט המסופק על ידי Google, Inc. Google Analytics משתמש בקובצי Cookie כדי לעזור לאתר לנתח את אופן השימוש של המשתמשים באתר. המידע שייצר העוגייה על השימוש שלך באתר ישודר ויאוחסן על ידי גוגל בשרתים בארצות הברית.</p>
                        <p>במקרה בו אנונימיזציה של IP תופעל באתר זה, כתובת ה-IP שלך תקוצר בתחום המדינות החברות באיחוד האירופי או צדדים אחרים להסכם על האזור הכלכלי האירופי. רק במקרים חריגים כל כתובת ה-IP תועבר תחילה לשרת גוגל בארה"ב ותקוצץ שם. אנונימיזציה של IP פעילה באתר זה.</p>
                        <p>גוגל תשתמש במידע זה בשם מפעילת אתר זה לצורך הערכת השימוש שלך באתר, עריכת דוחות על פעילות האתר עבור מפעילי האתרים ומתן להם שירותים אחרים הקשורים לפעילות באתר ושימוש באינטרנט.</p>
                        <p>כתובת ה-IP שהדפדפן שלך מעביר במסגרת Google Analytics לא תקושר לנתונים אחרים שבידי Google. אתה רשאי לסרב לשימוש בעוגיות על ידי בחירת ההגדרות המתאימות בדפדפן שלך. עם זאת, שים לב שאם תעשה זאת, ייתכן שלא תוכל להשתמש במלוא הפונקציונליות של אתר זה. אתה יכול גם לבטל את הסכמתך למעקב על ידי Google Analytics עם תוקף לעתיד על ידי הורדה והתקנה של תוסף הדפדפן לביטול הסכמה של Google Analytics עבור דפדפן האינטרנט הנוכחי שלך.</p>
                        <h2 class="text-primary">אבטחת מידע</h2>
                        <p>...... נועד לכלול כמה שכבות של אבטחה.</p>
                        <ul className="privacy-list" >
                            <li >אנו משתמשים בשכבת שקעים מאובטחת (SSL)/אבטחת שכבת תחבורה (TLS) כדי ליצור מנהרה מאובטחת כדי להגן על נתונים במעבר בין המכשיר שלך לבין השרתים של ...</li>
                            <li>אנחנו לא שומרים את סיסמת החשבון שלך בפועל, אלא מאחסנים וממליחים אותה כדי לעזור להגן עליה מפני פשרה אפשרית.</li>
                            <li>אנו מגבילים באופן צר את מספר העובדים שיש להם גישה לנתוני משתמשים באמצעות בקרות מדיניות וגישה טכניות.</li>
                        </ul>
                        <p>שום שידור דרך האינטרנט אינו מאובטח לחלוטין, ולכן איננו יכולים להבטיח באופן מוחלט שצדדים לא מורשים לא יוכלו להביס את אמצעי האבטחה שלנו. אתה משתמש ב... על אחריותך בלבד, ואחראי לנקוט באמצעים סבירים לאבטחת חשבונך (כגון בחירת סיסמה חזקה וייחודית ושמירתה בסוד).</p>
                        <p>אנחנו תמיד מחפשים נקודות תורפה בק..... אם תגלה פגיעות בשירות שלנו, נודה על הדיווח שלך ונמליץ לך ליידע אותנו מיד. אם תיתן לנו זמן סביר להגיב לדיווח שלך לפני פרסום מידע כלשהו לציבור, ותעשה מאמץ בתום לב להימנע מהפרות פרטיות, הרס נתונים והפרעה או השפלה של השירות שלנו במהלך המחקר שלך, לא ננקוט פעולה משפטית כלשהי. נגדך או לבקש מאכיפת החוק לחקור את מעשיך.</p>
                        <p>כדי לדווח על פגיעות, נא לשלוח דוא"ל ל- security@.....com.</p>
                        <h2 class="text-primary">גישה, תיקון ומחיקה של מידע</h2>
                        <p>אם תרצה לבקש גישה, תיקון או מחיקה של מידע אישי ... שומרת עליך, אנא שלח דוא"ל לsupport@abc.com.</p>
                        <h2 class="text-primary">שינויים במדיניות זו</h2>
                        <p>אנו עשויים לשנות את מדיניות הפרטיות הזו מעת לעת. הגרסה העדכנית ביותר של המדיניות תשלוט על השימוש שלנו במידע שלך ותמיד תהיה בכתובת https://www.abc.com/privacy. אם נבצע שינויים שלדעתנו ישנו באופן מהותי את זכויותיך, נודיע לך על כך מראש.</p>
                        <h2 class="text-primary">איש קשר</h2>
                        <p>נשמח לשמוע ממך. abc מקבלת בברכה שאלות, חששות ומשוב לגבי מדיניות זו. אם יש לך הצעות עבורנו, הודע לנו בכתובת privacy@abc.com.</p>

                    </Container>
                </section>
            </div>

        </>
    )
}

export default Privacy;