import React from "react";

const Recommended = () => {
  return (
    <div>
      
      <p class="text-center">זהו התוכן של הכרטיסייה מומלצים.</p>
    </div>
  );
};

export default Recommended;