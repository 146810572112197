import React, { useEffect, useRef, useState, useMemo } from "react";
import * as d3 from "d3";
import Tree from "react-d3-tree";
import "./Tree.css";
import { useCenteredTree } from "./TreeHelper";
import Tooltip from "./Tooltip";

const NestedTreeChart = ({ data, width, height }) => {
  const limitChildren = (node) => {
    if (!node.children) {
      return node;
    }

    const limitedChildren = node.children.slice(0, 2);
    const remainingChildren = node.children.slice(2);

    const limitedNode = {
      ...node,
      children: limitedChildren.map(limitChildren),
    };

    if (remainingChildren.length > 0) {
      const remainingNode = {
        name: "Remaining",
        children: remainingChildren.map(limitChildren),
      };

      limitedNode.children.push(remainingNode);
    }

    return limitedNode;
  };

  const limitedData = limitChildren(data);

  const getNodeStyles = (nodeData) => {
    if (nodeData.depth === 0) {
      return {
        fill: "#f0f0f0",
        stroke: "#333",
        strokeWidth: 2,
      };
    }

    if (nodeData.depth === 1) {
      return {
        fill: nodeData.nodeDatum.side === "pros" ? "#00ff00" : "#ff0000",
        stroke: "#333",
        strokeWidth: 2,
      };
    }

    return {};
  };

  const [translate, containerRef] = useCenteredTree();
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [centeredNode, setCenteredNode] = useState(null);
  const svgRef = useRef();

  const straightPathFunc = (linkDatum, orientation) => {
    const { source, target } = linkDatum;
    return orientation === "horizontal"
      ? `M${source.y},${source.x}L${target.y},${target.x}`
      : `M${source.x},${source.y}L${target.x},${target.y}`;
  };
  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const renderRectSvgNode = ({ nodeDatum, toggleNode, tree, x, y }) => {
    const color = nodeDatum.side === "pros" ? "#007a47" : "#ca3507"; // green for pros, red for cons
    const handleMouseOver = (event, d) => {
      const [x, y] = d3.pointer(event);
      const svg = d3.select(svgRef.current);
      const svgX = parseFloat(svg.attr("x")) || 0;
      const svgY = parseFloat(svg.attr("y")) || 0;

      if (nodeDatum.title) {
        setTooltipContent(nodeDatum.title);
      }

      setTooltipPosition({ x: x + svgX, y: y + svgY });
    };

    const handleMouseOut = () => {
      setTooltipContent("");
    };
    console.log("check", tooltipContent);

    return (
      <g>
        <rect
          width="40"
          height="30"
          x="-15"
          y="-5"
          fill="white"
          rx="5" // add rounded corners for a softer look
          ry="2"
          stroke="none"
        />
        <rect
          width="30"
          height="20"
          x="-15"
          rx="2"
          onClick={toggleNode}
          fill="white"
          stroke={color}
          strokeWidth="2"
          strokeLinejoin="round"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        />
      </g>
    );
  };

  return (
    <div className="App">
      <Tree
        data={data}
        translate={translate}
        orientation="vertical" // Add this line for vertical tree
        nodeSvgShape={{
          shapeGenerator: () => {
            return { shape: "square" };
          },
        }}
        nodeSize={{ x: 170, y: 35 }}
        separation={{ siblings: 0.25, nonSiblings: 0.2 }}
        transitionMode="legacy"
        draggingEnabled={false}
        renderCustomNodeElement={renderRectSvgNode}
      />
      <div
        style={{ position: "relative" }}
        className="d-flex justify-content-center sunbrust m-auto"
      >
        <svg ref={svgRef} width={width} height={height}></svg>
        {tooltipContent && (
          <Tooltip
            x={tooltipPosition.x}
            y={tooltipPosition.y}
            title={tooltipContent}
          />
        )}
      </div>
    </div>
  );
};

export default NestedTreeChart;
