import {Container} from "react-bootstrap"
import Header from "../../../Layouts/Header";
import Footer from "../../../Layouts/Footer";

function Terms(){
    return(
        <>
        <Header />
        <div class="privacy-dv" dir="rtl">
        <section class="privacy-pg" >
        <Container>
        <p class="text-danger">התנאים וההגבלות ומדיניות הפרטיות מתעדכנים כעת כדי לשקף שהם תואמים ל-GDPR ול-CCPA.</p>
                        <p class="text-danger">הוא אתר חינמי שאינו מניב הכנסות. אין לו מודעות, וגם לא נעשה שימוש לרעה בנתוני המשתמשים או נמכרים מחדש. נתוני המשתמש משמשים לשיפור האתר, למשל. כדי לראות באילו פונקציות נעשה שימוש תכוף, כדי להציג תוכן משתמשים, ולהפוך את האתר לידידותי על ידי זיהוי הטענות שהמשתמשים כבר ראו.</p>
                        <p class="text-danger my-5 mt-0">אנא צור איתנו קשר אם יש לך שאלות כלשהן.</p>
                        <p class=" my-5">8 בספטמבר 2023</p>
                        <h1 class="text-primary my-5" >מדיניות הפרטיות</h1>
                        <p>ברוכים הבאים ל-diyun תודה שהצטרפת לדיונים באתר שלנו. אנחנו שמחים שאתה כאן.</p>
                        <p>תנאי שירות אלה ("תנאים") חלים על השימוש שלך באתר ובשירותים של diyun. אנא קרא אותם בעיון מכיוון ששימוש ב-diyun, אתה מסכים להיות מחויב לתנאים אלה. אם אתה משתמש ב-diyun בשם ארגון, אתה מסכים לתנאים אלה בשם אותו ארגון.</p>
                        <h2 class="text-primary">כיצד אנו משתמשים במידע שלך</h2>
                        <p>תמצא כמה סוגי תוכן בשירות שלנו.</p>
                        <p><span class="text-decoration-underline">באישור שלך.</span> אנו עשויים לחלוק את המידע שלך בהסכמתך, לאחר שנודיע לך איזה מידע ישותף ועם מי.</p>
                        <p>אתה האחראי הבלעדי לתוכן שאתה מפרסם ב-diyun ולוקח על עצמך את כל הסיכונים הקשורים אליו, כולל קניין רוחני או תביעות משפטיות אחרות. על ידי פרסום תוכן ב-diyun, אתה מצהיר שיש לך את הזכויות הדרושות לחומר זה, וכי עשייתה זו אינה סותרת הסכמים אחרים שעשית.</p>
                        <p>אנחנו לא מסננים מראש תוכן של אף אחד, אבל יש לנו את הזכות - אם כי אין לנו חובה - לבדוק ולהסיר את התרומות שלך ל-diyun. אנו לא נושאים באחריות לתוכן שאתה או אחרים מפרסמים בשירות שלנו.</p>
                        <p><span class="text-decoration-underline">באישור שלך.</span> אנו עשויים לחלוק את המידע שלך בהסכמתך, לאחר שנודיע לך איזה מידע ישותף ועם מי.</p>
                        <p>diyun מעניק לך רישיון אישי, כלל עולמי, ללא תמלוגים, לא ניתן להקצאה ולא בלעדי להשתמש בתוכנה שאנו מספקים לך כדי להשתמש בשירות שלנו. רישיון זה נועד אך ורק לאפשר לך להשתמש ב-diyun כפי שמותר בתנאים אלה. אם תפר תנאים אלה, אנו עשויים לסיים רישיון זה.</p>
                        <p>אינך רשאי להשתמש באף אחד מהסימנים המסחריים, הלוגואים, שמות הדומיין או מאפייני המותג המובהקים של diyun ללא רשותנו, אלא אם מותר אחרת בחוק.</p>
                        <h2 class="text-primary">כיצד אנו משתמשים במידע שלך</h2>
                        <p>שואפת לספק שירות מעולה. לשם כך, אנו מבקשים ממך לכבד כללים אלה, ואנו שומרים לעצמנו את הזכות לסגור את חשבונך אם לא תמלא אחריהם.</p>
                        <ul className="privacy-list">
                            <li>אל תשתמש ב-diyun באופן שמפר חוקים, תקנות, פקודות או הנחיות כלשהן.</li>
                            <li>אל תשתמש ב-diyun באופן שמפר חוקים, תקנות, פקודות או הנחיות כלשהן.</li>
                            <li>אל תשתמש ב-diyun באופן שמפר חוקים, תקנות, פקודות או הנחיות כלשהן.</li>
                            <li>אל תשתמש ב-diyun באופן שמפר חוקים, תקנות, פקודות או הנחיות כלשהן.</li>
                            <li>אל תשתמש ב-diyun באופן שמפר חוקים, תקנות, פקודות או הנחיות כלשהן.</li>
                        </ul>
                        <h2 class="text-primary">זחילה דיון וייצוא דיונים</h2>
                        <p>סריקת diyun מותרת בהתאם להוראות הרובוטים שלנו, ותוכל להשתמש בפונקציונליות הייצוא שלנו כדי להוריד דיונים לשימוש פרטי. אנא אל תשתמש באמצעים אחרים כדי לנטר או להעתיק חומר כלשהו ב-diyun, בין אם באופן ידני או באמצעים אוטומטיים (כלומר, גרידה), ללא הסכמה בכתב מראש. אם תרצה לבקש הסכמה, אנא צור קשר עם support@diyun.com.</p>
                        <h2 class="text-primary">הזכויות שלנו</h2>
                        <p>אנחנו תמיד עובדים כדי לשפר את diyun ולשפר את הפורום שלנו, אז אנחנו שומרים לעצמנו זכויות מסוימות. אנו עשויים לשנות את השירותים שלנו מעת לעת, ולשנות, להשעות או לסיים חשבונות משתמש שאינם עומדים בתנאים שלנו. diyun אינה אחראית לכל נזק כתוצאה מפעולות אלו.</p>
                        <h2 class="text-primary">אתרים ושירותים אחרים</h2>
                        <p>diyun מכיל קישורים לאתרי אינטרנט ושירותים אחרים שאינם בבעלותנו או בשליטתנו. איננו תומכים או לוקחים אחריות על אתרים, מידע, חומרים, מוצרים או שירותים של צד שלישי כלשהם.</p>
                        <h2 class="text-primary">פְּרָטִיוּת</h2>
                        <p>מדיניות הפרטיות שלנו מסדירה את האיסוף והשימוש שלנו במידע האישי שלך. אנא עיין גם בהוראות אלה.</p>
                        <h2 class="text-primary">בִּטָחוֹן</h2>
                        <p>אנו מתייחסים ברצינות רבה לאבטחת diyun. אנו עובדים קשה כדי להגן על התוכן והחשבון שלך, אך איננו יכולים להבטיח באופן מוחלט שצדדים שלישיים לא יוכלו להביס את אמצעי האבטחה שלנו. אנא הודע לנו מיד אם תגלה פשרה או שימוש לא מורשה בחשבון שלך.</p>
                        <h2 class="text-primary">יְלָדִים</h2>
                        <p>diyun מיועד רק למשתמשים בני 13 ומעלה. אם נדע שילד מתחת לגיל 13 משתמש בשירות שלנו, אנו נסגור את החשבון של הילד.</p>
                        <h2 class="text-primary">זכויות יוצרים</h2>
                        <p>מדיניות זכויות היוצרים של diyun תואמת את Digital Millennium Copyright Act. למידע נוסף, עיין במדיניות זכויות היוצרים שלנו.</p>
                        <h2 class="text-primary">סיום חשבון</h2>
                        <p>אתה יכול להשבית את חשבון diyun שלך בכל עת על ידי שליחת אימייל לכתובת support@diyun.com. המשמעות היא שחשבון המשתמש שלך לא יהיה פעיל יותר בשירות שלנו, ואנו נמחק את המידע המאפשר זיהוי אישי שיש לנו עליך תוך 90 יום.</p>
                        <p>תוכן שתרמת לדיונים ב-diyun יישאר זמין למשתמשי diyun שהרשית בעבר לראות אותו, גם לאחר שתשבית את חשבונך, אם כי זהותך כמחבר תהיה אנונימית. משתמשים אחרים עדיין יוכלו לראות את הפרופיל המשויך לחשבון שלך, אך הוא לא יכיל עוד מידע אישי מזהה.</p>
                        <h2 class="text-primary">כתבי ויתור</h2>
                        <p>diyun מסופק "כמות שהוא" ללא כל אחריות, מפורשת או משתמעת.</p>
                        <p>diyun מתנער מכל האחריות והתנאים של סחירות, התאמה למטרה מסוימת ואי-הפרה.</p>
                        <p>(מדינות מסוימות אינן מתירות כתבי ויתור אלה, ולכן ייתכן שהוראה זו לא תחול עליך.)</p>
                        <h2 class="text-primary">הגבלת אחריות</h2>
                        <p>במידה המרבית המותרת בחוק, diyun לא תישא באחריות לכל נזק עקיף, מקרי, מיוחד, תוצאתי או עונשי, כל אובדן רווחים או הכנסות, או כל אובדן נתונים, שימוש, מוניטין או הפסדים בלתי מוחשיים אחרים הנובעים מ. (א) הגישה שלך, השימוש, חוסר היכולת לגשת, או חוסר היכולת להשתמש ב-Kialo; (ב) כל התנהגות או תוכן של צד שלישי ב-Kialo, לרבות כל התנהגות מכפישה, פוגענית או בלתי חוקית של צדדים שלישיים; או (ג) כל גישה לא מורשית, שימוש או שינוי בתוכן שלך.</p>
                        <p>(מדינות מסוימות אינן מתירות מגבלות אלה, כך שייתכן שהוראה זו לא תחול עליך.)</p>
                        <h2 class="text-primary">בוררות וביטול הסכמה</h2>
                        <p>אנו רוצים לפתור כל מחלוקת בצורה הוגנת ומהירה. לשם כך, אם יש לך בעיה כלשהי עם diyun, אנא צור איתנו קשר ואנו נעבוד איתך בתום לב כדי לנסות לפתור את העניין.</p>
                        <p>אם לא נוכל לפתור את המחלוקת באופן לא פורמלי, אתה ו-diyun מסכימים לפתור כל תביעה זה נגד זה באמצעות בוררות סופית ומחייבת.</p>
                        <p>אנו מסכימים להגיש את המחלוקת לבורר יחיד במסגרת כללי הבוררות המסחרית של האגודה האמריקאית לבוררות (AAA) כולל הכללים האופציונליים לאמצעי חירום להגנה והנהלים המשלימים לסכסוכים הקשורים לצרכן, או, בהסכמה הדדית נפרדת, מוסד בוררות אחר. הכללים של AAA ותיאור של תהליך הבוררות זמינים בכתובת www.adr.org.</p>
                        <p>מיקום הבוררות והקצאת שכר הטרחה והעלויות ייקבעו על פי כללי AAA, אלא ש-diyun תחזיר לך את כל דמי הניהול של AAA בסכסוכים הכפופים להליכים המשלימים לסכסוכים הקשורים לצרכן, אלא אם כן יקבע הבורר. כי תביעה או תביעה שכנגד הוגשה למטרות הטרדה או שהיא קלת דעת בעליל.</p>
                        <p>הפסק שניתן על ידי הבורר יכלול עלויות בוררות, שכר טרחת עורכי דין סביר ועלויות סבירות למומחה ולעדים אחרים. עם זאת, אם תביעתך לפיצויים אינה עולה על $75,000, .... ישלם עבור כל שכר טרחת הגשה, ניהול ובורר סבירים, כל עוד הבורר יקבע שתביעתך אינה קלת דעת. אם תזכה בפסק בוררות שהוא נדיב יותר מכל הצעה שהצענו ליישוב המחלוקת, .... ישלם לך 1,500 דולר בנוסף לפרס. כל פסק דין על הפסק שניתן על ידי הבורר יכול להירשם בכל בית משפט בעל סמכות שיפוט מוסמכת.
חוק הבוררות הפדרלי מסדיר את כל המחלוקות בינך לבין קיאלו. הבורר יחליט האם ניתן לבורר סכסוך.</p>
                        <p>אם תבטל את הסכמתך להסכם הבוררות (כמפורט להלן), או אם הסכם הבוררות יימצא בלתי ניתן לאכיפה, אתה מסכים לפתור כל תביעה שיש לך עם Kialo באופן בלעדי בבית משפט מדינתי או פדרלי הממוקם בדלאוור, ולהגיש בקשה סמכות השיפוט האישית של בתי המשפט הממוקמים בדלאוור במטרה להתדיין בכל המחלוקות הללו.</p>
                        <p>שום דבר לא ימנע מאף אחד מהצדדים לבקש צו מניעה או סעד הוגן אחר מבתי המשפט בעניינים הקשורים לאבטחת מידע, קניין רוחני או גישה בלתי מורשית לשירותים.</p>
                        <h2 class="text-primary">זכותך לבטל את הסכמתך לבוררות</h2>
                        <p>אתה יכול לבטל את ההסכם לבוררות. אם תעשה זאת, לא אתה ולא ....לא תוכל לדרוש מהאחר להשתתף בהליך בוררות. כדי לבטל את הסכמתך, עליך להודיע .... בתוך 90 יום מהתאריך שהסכמת לראשונה לתנאים אלה.</p>
                        <p>הודעת ביטול ההסכמה בכתב חייבת לכלול (1) שמך וכתובת המגורים שלך; (2) כתובת הדוא"ל המשויכת לחשבונך; (3) הצהרה ברורה שאתה רוצה לבטל את הסכמתך להסכם בוררות זה; ו-(4) החתימה שלך.</p>
                        <p>כדי לבטל את הסכמתך לבוררות, אנא שלח דוא"ל אל optout@xyz.com.</p>
                        <h2 class="text-primary">חוק וסמכות שיפוט</h2>
                       <p>תנאים אלה יהיו כפופים לחוקי מדינת דלאוור, למעט עקרונות ניגוד החוקים שלה.</p>
                       <p>לגבי תביעות שאינן כפופות לבוררות, כל אחד מאיתנו מסכים להיכנע לסמכות השיפוט האישית של בית משפט ממלכתי הממוקם בדלאוור או בבית המשפט המחוזי של ארצות הברית עבור מחוז דלאוור.</p>
                       <h2 class="text-primary">הסכם שלם והפרדה</h2>
                       <p>התנאים, והחומרים האחרים המשולבים בהפניה, מהווים את ההסכם המלא והשלם בינך לבין .... אם יימצא כי הוראה כלשהי בתנאים אלה אינה ניתנת לאכיפה, שאר ההוראות של תנאים אלה יישארו בתוקף ובתוקף.</p>
                       <h2 class="text-primary">אין ויתור</h2>
                       <p>שום ויתור על תנאי כלשהו בתנאים אלה לא יהווה ויתור נוסף או מתמשך על תנאי זה. .... אי קביעת זכות או הוראה כלשהי לפי תנאים אלה אינו מהווה ויתור על הזכות או ההוראה הזו.</p>
                       <h2 class="text-primary">שינוי</h2>
                       <p>התנאים עשויים להשתנות מעת לעת. התאריך של התיקונים האחרונים יהיה תמיד בכתובת https://www.xyz.com/terms. אם נבצע שינויים שלדעתנו ישנו באופן מהותי את זכויותיך, נודיע לך על כך מראש. תסכים לקבל כל שינוי או תיקונים בתנאים על ידי המשך השימוש .....</p>
                       <h2 class="text-primary">איש קשר</h2>
                       <p>אנו מברכים על כל השאלות, החששות והמשוב שיש לך לגבי תנאים אלה. אם יש לך הצעות עבורנו, הודע לנו בכתובת support@xyz.com.</p>
                       <p><a href="#" class="link-primary text-decoration-underline">יתרונות לורם איפסום באתר lorem-ipsum.co.il:<br /> אורך מילים, משפטים ופסקאות מגוון ואמיתי</a></p>

        </Container>
        </section>
        <Footer />
        </div>
        </>
    )
}

export default Terms;