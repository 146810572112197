import React from "react";

const Recent = () => {
  return (
    <div>
      
      <p class="text-center">זהו התוכן של הכרטיסייה אחרונים.</p>
    </div>
  );
};

export default Recent;